import Page from "../../components/Page";
import "./styles.scss";

export default function UpcomingEvents() {
  return (
    <Page title="Upcoming Events">

    </Page>
  );
}
