import "./styles.scss";

export default function EmailNewsletter() {
  return (
    <>
      <iframe
        title="contact form"
        src="https://us10.list-manage.com/contact-form?u=6f5e1ed7f167b48d75116ef71&form_id=151897928f1b4371c94a2d4fd17af562"
        className="form-container"
      ></iframe>
    </>
  );
}
